








































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import {
    deleteList,
    getList,
    getListCustomFields,
    updateList,
} from '@/api/consoleApi/recipients'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    CustomFieldTypeEnum,
    CustomField,
} from '@/utils/customFields'
import VsTabs from '@/components/VsTabs/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'
import VsListNameModal from '@/modules/lists/components/VsListNameModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsCloneListModal from './../../components/VsCloneListModal/Index.vue'
import { UserModule } from '@/store/modules/user'
import { get } from 'lodash'
import moment from 'moment'

@Component({
    name: 'IndexIdList',
    components: {
        VsContainer,
        VsTabs,
        VsListNameModal,
        VsConfirm,
        VsDropdownButton,
        VsCloneListModal,
        VsTabsHeading,
    },
})
export default class extends Vue {
    private list: any = null
    private loading = false
    private customFields: CustomField[] = []
    private checkedContacts = 0
    $refs: any

    beforeMount () {
        this.getList()
        this.getCustomFields()
    }

    get disabledPrimaryActions () {
        return this.$route.name === 'contacts' && this.checkedContacts > 0
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get hasTag () {
        return this.userRules.tag
    }

    get activeTab () {
        return this.$route.name
    }

    get hasEmail () {
        return this.customFields.find(el => el.type === CustomFieldTypeEnum.email)
    }

    get hasSms () {
        return this.customFields.find(el => el.type === CustomFieldTypeEnum.mobile)
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canProfilation () {
        return this.userRules.segments
    }

    get isDeleteDisabled () {
        if (!this.list) return false
        if (this.list.last_campaign_sent_timestamp) {
            return moment().diff(moment.unix(this.list.last_campaign_sent_timestamp), 'days') <= 10
        }
        return false
    }

    get tabs () {
        const tabs = [
            {
                label: this.$t('lists.listIdIndex.contactsList'),
                id: 'contacts',
                to: {
                    name: 'contacts',
                },
            },
            {
                label: this.$t('lists.listIdIndex.tags'),
                id: 'tags',
                disabled: !this.hasTag,
                iconName: !this.hasTag ? 'lock' : '',
                to: {
                    name: 'tags',
                },
                clickDisabled: this.$root.$vsOpenBlockedModal,
            },
            {
                label: this.$t('lists.listIdIndex.configurationFields'),
                id: 'listCustomFields',
                to: {
                    name: 'listCustomFields',
                },
            },
            {
                label: this.$t('lists.listIdIndex.importHistory'),
                id: 'importHistory',
                to: {
                    name: 'importHistory',
                },
            },
            {
                label: this.$t('lists.listIdIndex.listSync'),
                id: 'listSync',
                to: {
                    name: 'listSync',
                },
            },
            {
                label: this.$t('lists.listIdIndex.settings'),
                id: 'listEdit',
                to: {
                    name: 'listEdit',
                },
            },
            {
                label: this.$t('lists.listIdIndex.statistics'),
                id: 'listStatistics',
                to: {
                    name: 'listStatistics',
                },
            },
            {
                label: this.$t('lists.listIdIndex.profiling'),
                id: 'profiling',
                iconName: this.canProfilation ? 'external-link-minor' : 'lock',
                url: this.canProfilation ? `/app/user/segments/browse/${this.listId}` : false,
                click: !this.canProfilation && this.$root.$vsOpenBlockedModal,
            },
        ]

        return tabs
    }

    private async getCustomFields () {
        const resp = await getListCustomFields(this.listId, {
            searchFields: 'type:in',
            search: `type:${[CustomFieldTypeEnum.email, CustomFieldTypeEnum.mobile]}`,
            searchJoin: 'or',
        })
        this.customFields = resp.data.data
    }

    private async editListName () {
        try {
            const listName = await this.$refs.listNameModal.open(this.list.name)
            try {
                await this.submit(listName)
            } catch (e) {
                return
            }
        } catch (e) {
            console.log(e)
        }
        this.$refs.listNameModal.close()
    }

    async submit (listName: string) {
        try {
            this.list.name = listName
            this.list.email_address_notify = this.list.email_address_notify || ''
            await updateList(this.listId, this.list)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Nome della lista modificato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante il salvataggio del nome della lista',
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async getList () {
        try {
            const resp = await getList(this.listId)
            this.list = resp.data.data
            delete this.list.integration
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Lista non trovata',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$router.replace({ name: 'listsIndex' })
                    return
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati della lista, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private async deleteList () {
        try {
            await this.$refs.deleteListConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            const resp = await deleteList(this.listId)

            const errorCode = get(resp, 'data.data.motivation.code', '')
            if (errorCode === 'CannotDeleteRecipientUsedInRecentCampaign') {
                this.$root.$vsToast({
                    heading: `Errore durante la cancellazione della lista`,
                    message: 'Non è possibile cancellare liste che sono state usate per inviare campagne negli ultimi 10 giorni.',
                    timeout: 3000,
                    aspect: VsToastAspectEnum.alert,
                })
                this.loading = false
                return
            }

            this.$root.$vsToast({
                heading: this.$tc('lists.index.deleteSuccess', 1),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: 'lists',
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$tc('lists.index.deleteError', 1),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private openBlockedFunctionality (e: any, isBlocked: boolean) {
        if (isBlocked) {
            e.preventDefault()
            this.$root.$vsOpenBlockedModal()
        }
    }
}
